(function($){
    $(window).on('load', function() {
        /*------------------ Preloader --------------------*/
        $(".loader").fadeOut();
        $("#preloder").delay(400).fadeOut(400);

        $("#year").text( (new Date).getFullYear() );
        $("#age").text( getAge("1996/03/27") );

        let timeout = null;

        $(window).scroll(function() {
            if (!timeout) {
                timeout = setTimeout(function () {
                checkNavBackground();
                timeout = null;
                }, 250);
            }
        });
    });

    let nav_dark_mode = false;
    function checkNavBackground() {
        let intro = $("#intro");
        let about = $("#about");
        let experience = $("#experience");
        let skills = $("#skills");
        let work = $("#work");
        let contact = $("#contact");

        let nav = $("#navigation-toggle");
        let nav_top = nav.offset().top;

        if(nav_top > intro.offset().top && nav_top < intro.offset().top+intro.outerHeight()) {
            $("#navigation-toggle").removeClass("dark");
            nav_dark_mode = false;
        }
        else if(nav_top > about.offset().top && nav_top < about.offset().top+about.outerHeight() && !nav_dark_mode) {
            $("#navigation-toggle").addClass("dark");
            nav_dark_mode = true;
        }
        else if(nav_top > experience.offset().top && nav_top < experience.offset().top+experience.outerHeight() && !nav_dark_mode) {
            $("#navigation-toggle").addClass("dark");
            nav_dark_mode = true;
        }
        else if(nav_top > skills.offset().top && nav_top < skills.offset().top+skills.outerHeight()) {
            $("#navigation-toggle").removeClass("dark");
            nav_dark_mode = false;
        }
        else if(nav_top > work.offset().top && nav_top < work.offset().top+work.outerHeight() && !nav_dark_mode) {
            $("#navigation-toggle").addClass("dark");
            nav_dark_mode = true;
        }
        else if(nav_top > contact.offset().top && nav_top < contact.offset().top+contact.outerHeight() && !nav_dark_mode) {
            $("#navigation-toggle").addClass("dark");
            nav_dark_mode = true;
        }
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /*------------------ Navigation --------------------*/
    var header_height = $('.intro-section').innerHeight();
    var navOpen = false;

    $(window).on('scroll resize',function(e) {
        if ($(this).scrollTop() > header_height) {
            $('#navigation-toggle').addClass('sticky');
        }else{
            $('#navigation-toggle').removeClass('sticky');
        }
        e.preventDefault();
    });

    $('#navigation-toggle').on('click', function(event) {
        if (navOpen) {
            $('.nav-section').removeClass('active');
        } else {
            $('.nav-section').addClass('active');
        }
        navOpen = !navOpen;
        event.preventDefault();
    });

    $('.close-nav').on('click', function(event) {
        $('.nav-section').removeClass('active');
        navOpen = false;
        event.preventDefault();
    });

    $('.menu-list').onePageNav({
        easing: 'swing'
    });



    /*------------------ Background set --------------------*/
    $('.set-bg').each(function() {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });



    /*------------------ Popup --------------------*/
    $('.portfolio-item').magnificPopup({
        type: 'image',
        mainClass: 'img-popup-warp',
        removalDelay: 500,
        image: {
            titleSrc: 'title',
            titleSrc: function(item) {
                var el = $(item.el[0]).find(".portfolio-description").clone();
                el.css("display","block");
                return el;
            }
        },
        callbacks: {
            resize: function() {
                var img = this.content.find('img');
                img.css('max-height', parseFloat(img.css('max-height')) - 200);
            }
        }
    });



    /*------------------ Isotope Filter --------------------*/
    var $container = $('.isotope_items');
    $container.isotope();

    $('.portfolio-filter li').on("click", function(){
        $(".portfolio-filter li").removeClass("active");
        $(this).addClass("active");
        var selector = $(this).attr('data-filter');
        $(".isotope_items").isotope({
            filter: selector,
            animationOptions: {
                duration: 750,
                easing: 'linear',
                queue: false,
            }
        });
        return false;
    });
    $('.portfolio-filter .active').click();


    /*------------------ Testimonial --------------------*/
    $('#test_slider').owlCarousel({
        dots: false,
        nav: true,
        loop: true,
        margin:30,
        smartSpeed: 700,
        items:1,
        autoplay:true,
        navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>']
    });


    /*------------------ WOW JS --------------------*/
    new WOW().init();


    /*------------------ CONTACT FORM  --------------------*/
    $('#contact-form').on('submit', function() {
        var send_btn = $('#send-form'),
            form = $(this),
            formdata = $(this).serialize(),
            chack = $('#form-chack');
        send_btn.text('Wait...');

        var message_box = $('#mail-message-box');

        function reset_form(){
            $("#name").val('');
            $("#email").val('');
            $("#massage").val('');
        }

        $.ajax({
            url:  $(form).attr('action'),
            type: 'POST',
            data: formdata,
            success : function(response){
                console.log(response);
                if (response == "success"){
                    send_btn.addClass('done');
                    send_btn.text('Success');
                    message_box.addClass('show');
                    message_box.addClass('success');
                    
                    setTimeout(function() {
                        reset_form();
                        send_btn.removeClass('done');
                        send_btn.text('Send Massage');
                        message_box.removeClass('show');
                        message_box.removeClass('success');
                    }, 5000);
                }
                else {
                    reset_form();
                    send_btn.addClass('error');
                    send_btn.text('Error');
                    message_box.html(response);
                    message_box.addClass('show');
                    message_box.addClass('error');

                    setTimeout(function() {
                        send_btn.removeClass('error');
                        send_btn.text('Send Massage');
                        message_box.removeClass('show');
                        message_box.removeClass('error');
                    }, 5000);
                }
            }
        });
        return false;
    });


})(jQuery);